export const getData = async (id) => {
  const response = await fetch(
    `https://cdn.prepr.io/publications/${id}?fields=items{guide_content{cdn_files}}`,
    {
      method: "get",
      headers: new Headers({
        Authorization:
          "Bearer 8a93f7a33a82bdd6f155afa73a198c01d6e093a4f9c2f704738a2f888190bae7",
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    }
  );
  return response.json();
};
