import ProjectableIcon from "./icons/projectable.svg";
import InteractiveIcon from "./icons/interactive.svg";
import TakeOffIcon from "./icons/takeoff.svg";
import ClassManagement from "./icons/classmanagement.svg";
import DiversitySupport from "./icons/diversitysupport.svg";
import IndividualIcon from "./icons/individual.svg";
import SolutionsIcon from "./icons/solutions.svg";
import FlightIcon from "./icons/flight.svg";
import ParisIcon from "./icons/pairs.svg";

import AssessmentIcon from "./icons/assessment.svg";
import LandingIcon from "./icons/landing.svg";
import PoolingIcon from "./icons/pooling.svg";
import DiversityampliationIcon from "./icons/diversityampliation.svg";

export const icons = {
  projectable: ProjectableIcon,
  interactive: InteractiveIcon,
  takeoff: TakeOffIcon,
  classmanagement: ClassManagement,
  diversitysupport: DiversitySupport,
  individual: IndividualIcon,
  solutions: SolutionsIcon,
  flight: FlightIcon,
  pairs: ParisIcon,
  assessment: AssessmentIcon,
  landing: LandingIcon,
  pooling: PoolingIcon,
  diversityampliation: DiversityampliationIcon,
};
