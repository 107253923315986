import React from "react";
import { icons } from "./iconsMapper";

export const transformElement = (element) => {
  switch (element.label) {
    case "Text":
      return formatText(element);
    case "Boolean":
      return formatBoolean();
    case "ElementBox":
      return formatElementBox(element);
    case "PublicationElement":
      return formatPublicationElement(element);
    case "Integer":
      return formatInteger(element);
    case "Asset":
      return formatAssets(element);
    default:
      return <span />;
  }
};

const formatAssets = ({ items }) =>
  items.map((item) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <img
        style={{ width: "fit-content", boxShadow: "1px 2px 5px 0px" }}
        height={items.length === 1 ? 200 : 150}
        src={item.cdn_files.items[0].url}
        alt={item.name}
      />
      <span>{item.name}</span>
    </div>
  ));

const formatInteger = (element) => <span>{element.value}</span>;

const formatPublicationElement = (element) => {
  const { items } = element;
  const subitems = Object.entries(items).reverse();
  const keys = Object.keys(items);

  // section title
  if (keys.length === 1 && keys.includes("section_type")) {
    return formatSection(items);
  }

  // resum sessio
  if ("description" in items && "type" in items && "percentage" in items) {
    return formatResumeSession(items);
  }

  // icon with text
  if ("description" in items && "type" in items) {
    return formatTextWithIcon(items);
  }

  // content guide block
  if (
    ("content" in items && "type" in items) ||
    ("type" in items && "multimedia" in items)
  ) {
    return formatBlock(items);
  }

  return <div>{subitems.map(([, item]) => transformElement(item))}</div>;
};

const formatBlock = ({ content, multimedia, type }) => (
  <div style={{ display: "flex", gap: 16, margin: "15px 0" }}>
    <div
      style={{
        width: 40,
        backgroundColor: getBgColor(type.body),
        borderRadius: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <Icon value={type.body} />
    </div>
    <div
      style={{
        flex: 1,
        display: "flex",
        gap: 16,
        flexDirection: "column",
      }}
    >
      {content && <div>{transformElement(content)}</div>}

      {multimedia && (
        <div style={{ display: "flex", justifyContent: "center", gap: 20 }}>
          {transformElement(multimedia)}
        </div>
      )}
    </div>
  </div>
);

const formatResumeSession = ({ description, type }) => (
  <div>
    <span style={{ color: getColor(type.body) }}>{translate(type.body)}</span>
    {formatText(description)}
  </div>
);

const formatTextWithIcon = ({ description, type }) => (
  <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
    <Icon value={type.body} />
    {formatText(description)}
  </div>
);

export const formatSection = ({ section_type }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: 16,
      marginTop: 50,
    }}
  >
    <Icon value={section_type.body} />
    <h3 style={{ color: getColor(section_type.body) }}>
      {translate(section_type.body)}
    </h3>
  </div>
);

const translate = (text) => {
  const capitalizeFirstLetter = (text) =>
    text.charAt(0).toUpperCase() + text.slice(1);
  return capitalizeFirstLetter(text);
};

const Icon = (props) => {
  const { value, ...rest } = props;
  const icon = value.replaceAll(" ", "");
  return <img {...rest} alt={value} src={icons[icon]} />;
};

export const formatElementBox = (element) => {
  const { items } = element;
  return <div>{items.map(transformElement)}</div>;
};

export const formatBoolean = () => (
  <div
    style={{
      width: 20,
      height: 20,
      borderRadius: "50%",
      backgroundColor: "#14ae7a",
      marginBottom: 4,
    }}
  />
);

export const formatText = (item) => {
  switch (item.format) {
    case "H1":
      return <h1>{item.body}</h1>;
    case "H2":
      return <h2>{item.body}</h2>;
    case "H3":
      return <h3>{item.body}</h3>;
    case "H4":
      return <h4>{item.body}</h4>;
    case "H5":
      return <h5>{item.body}</h5>;
    case "H6":
      return <h6>{item.body}</h6>;
    default: {
      const regex = new RegExp(/\$\$(.*?)\$\$/, "gm");
      const expressions = item.body.match(regex);
      let content = item.body;

      for (let index = 0; index < expressions?.length; index++) {
        const expression = expressions[index];
        content = content.replace(
          expression,
          `<span id="latex-${item.id}-${index}">${expression}</span>`
        );
      }
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }
  }
};

const getColor = (value) => {
  switch (value) {
    case "takeoff":
      return "#830883";
    case "flight":
      return "#df9d24";
    case "landing":
      return "#0084f5";
    default:
      return "white";
  }
};

const getBgColor = (value) => {
  const colors = ["individual", "pairs", "pooling"];
  if (colors.includes(value)) {
    return "#ededed";
  }
  return "#bbe7db";
};
