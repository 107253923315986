import cx from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { transformElement } from "../utils/jsonParser";
import { getData } from "../utils/api";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import Latex from "react-latex";
import ReactDOM from "react-dom";

ChartJS.register(ArcElement, Tooltip);

const getIdFromPath = () => {
  return window.location.pathname.split("/")[1];
};

export default function Preprio() {
  const [data, setData] = useState();
  const [graphData, setGraphData] = useState();

  const id = getIdFromPath();

  useEffect(() => {
    if (id) {
      getData(id).then((response) => {
        const items = response.items["es-ES"];
        setData(items);
        mapGraphData(items);
      });
    }
  }, [id]);

  const mapGraphData = (items) => {
    const labels = items.session_parts.items.map(
      ({ items }) => items.type.body
    );
    const values = items.session_parts.items.map(
      ({ items }) => items.percentage.value
    );
    setGraphData({
      labels,
      datasets: [
        {
          label: "# of Votes",
          data: values,
          backgroundColor: ["#830883", "#df9d24", "#0084f5"],
          borderWidth: 0,
        },
      ],
    });
  };

  useEffect(() => {
    if (!data) return;
    const latexElements = document.querySelectorAll("[id^='latex']");
    for (let i = 0; i < latexElements.length; i++) {
      const element = latexElements[i];
      ReactDOM.render(
        <Latex>{element.innerHTML}</Latex>,
        document.getElementById(element.id)
      );
    }
  }, [data]);

  if (!id) return <h1>Enganxa un ID en la url per veure una guia</h1>;

  if (!data) return null;

  return (
    <div className={styles.app}>
      <div className={styles.container}>
        <div className={cx(styles.block, styles.basicInfo)}>
          <h2>Información Básica</h2>
          {transformElement(data.basic_information)}
        </div>

        <div className={cx(styles.block, styles.multiple, styles.dimensions)}>
          <div>
            <h2>Dimensiones</h2>
            {transformElement(data.dimensions)}
          </div>
          <div>
            <h2>Blocs Innovamat</h2>
            {transformElement(data.innovamat_blocks)}
          </div>
        </div>

        <div
          className={cx(styles.block, styles.multiple, styles.resumeSession)}
        >
          <div>
            <h2>Resum Sessió</h2>
            {transformElement(data.session_parts)}
          </div>
          <div>{graphData && <Pie data={graphData} />}</div>
        </div>

        <div className={cx(styles.block, styles.material)}>
          <h2>Material</h2>
          {transformElement(data.material_summary)}
        </div>

        <div className={styles.guideContent}>
          {transformElement(data.guide_content)}
        </div>
      </div>
    </div>
  );
}
